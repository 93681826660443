var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('b',{staticClass:"h1"},[_vm._v("Add Supplier")])]),_c('b-card-body',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b',[_vm._v("Comapany Info")]),_c('b-row',{staticClass:"box-list"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Country:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Country"},model:{value:(_vm.model.country),callback:function ($$v) {_vm.$set(_vm.model, "country", $$v)},expression:"model.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Address:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Address"},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Tax code:","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"placeholder":"Tax code"},model:{value:(_vm.model.tin),callback:function ($$v) {_vm.$set(_vm.model, "tin", $$v)},expression:"model.tin"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Status:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","reduce":function (x) { return x.value; },"options":_vm.activeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Company website:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"NaCompany websiteme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.model.web_company),callback:function ($$v) {_vm.$set(_vm.model, "web_company", $$v)},expression:"model.web_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Zip code:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Zip code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Zip code"},model:{value:(_vm.model.zip_code),callback:function ($$v) {_vm.$set(_vm.model, "zip_code", $$v)},expression:"model.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Certificate of Business registration code:","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"placeholder":"Certificate of Business registration code"},model:{value:(_vm.model.business_license),callback:function ($$v) {_vm.$set(_vm.model, "business_license", $$v)},expression:"model.business_license"}})],1)],1)],1)],1)],1),_c('b-col',{staticClass:"box-list",attrs:{"md":"12"}},[_c('b',[_vm._v("Contact Info")]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Contact name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Contact name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Contact name"},model:{value:(_vm.model.contact_name),callback:function ($$v) {_vm.$set(_vm.model, "contact_name", $$v)},expression:"model.contact_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Email:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Phone:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Phone"},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Short content:","label-cols-md":"3"}},[_c('b-form-textarea',{attrs:{"placeholder":"Short content"},model:{value:(_vm.model.short_content),callback:function ($$v) {_vm.$set(_vm.model, "short_content", $$v)},expression:"model.short_content"}})],1)],1)],1)],1)],1),_c('b-col',{staticClass:"box-list",attrs:{"md":"12"}},[_c('b',[_vm._v("Payment infor")]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Bank name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Bank name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Bank name"},model:{value:(_vm.model.name_bank),callback:function ($$v) {_vm.$set(_vm.model, "name_bank", $$v)},expression:"model.name_bank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Swift code:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Swift code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Swift code"},model:{value:(_vm.model.swift_code),callback:function ($$v) {_vm.$set(_vm.model, "swift_code", $$v)},expression:"model.swift_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Branch:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Branch"},model:{value:(_vm.model.branch_bank),callback:function ($$v) {_vm.$set(_vm.model, "branch_bank", $$v)},expression:"model.branch_bank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Account name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Account name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Account name"},model:{value:(_vm.model.account_holder_bank),callback:function ($$v) {_vm.$set(_vm.model, "account_holder_bank", $$v)},expression:"model.account_holder_bank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Account Number:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Account Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":"Account Number"},model:{value:(_vm.model.number_bank),callback:function ($$v) {_vm.$set(_vm.model, "number_bank", $$v)},expression:"model.number_bank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-col',{staticClass:"box-list",attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Position:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Position"},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Avatar:","label-cols-md":"3"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"max-width":"200px"}},[_c('b-aspect',{attrs:{"aspect":"1:1"}},[_c('b-img-lazy',{staticStyle:{"position":"absolute","max-width":"200px"},attrs:{"src":_vm.model.avatar,"fluid":"","thumbnail":"","rounded":""}}),_c('div',{staticClass:"fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"},[_c('b-form-file',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-center img-box",attrs:{"accept":".jpg, .png, .gif"},on:{"change":_vm.previewFiles}}),_c('b-button',{staticClass:"btn-icon rounded-circle btn-add",attrs:{"variant":"outline-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)])],1)],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }