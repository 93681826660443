<template>
  <b-card>
    <b-card-header><b class="h1">Add Supplier</b></b-card-header>
    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b>Comapany Info</b>
              <b-row class="box-list">
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Name:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="*Country:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.country"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Country"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="*Address:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Address"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.address"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Address"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="*Tax code:" label-cols-md="3">
                      <b-form-input
                        v-model="model.tin"
                        placeholder="Tax code"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Status:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Status"
                        rules="required"
                      >
                        <v-select
                          v-model="model.is_active"
                          label="text"
                          :reduce="x => x.value"
                          :options="activeOptions"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Company website:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="NaCompany websiteme"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.web_company"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Zip code:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Zip code"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.zip_code"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Zip code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Certificate of Business registration code:"
                      label-cols-md="3"
                    >
                      <b-form-input
                        v-model="model.business_license"
                        placeholder="Certificate of Business registration code"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="box-list" md="12">
              <b>Contact Info</b>
              <b-row>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Contact name:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Contact name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.contact_name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Contact name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="*Email:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Phone:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.phone"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Phone"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Short content:" label-cols-md="3">
                      <b-form-textarea
                        v-model="model.short_content"
                        placeholder="Short content"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="box-list" md="12">
              <b>Payment infor</b>
              <b-row>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Bank name:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Bank name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.name_bank"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Bank name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="*Swift code:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Swift code"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.swift_code"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Swift code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="*Branch:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Branch"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.branch_bank"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Branch"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Account name:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Account name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.account_holder_bank"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Account name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="*Account Number:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Account Number"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.number_bank"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          placeholder="Account Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="box-list" md="12">
              <b-row>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Position:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Position"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.position"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Position"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group label="*Avatar:" label-cols-md="3">
                      <div
                        class="d-flex align-items-center justify-content-center"
                        style="max-width: 200px;"
                      >
                        <b-aspect aspect="1:1">
                          <b-img-lazy
                            :src="model.avatar"
                            fluid
                            thumbnail
                            rounded
                            style="position: absolute; max-width: 200px;"
                          />
                          <div
                            class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
                          >
                            <b-form-file
                              accept=".jpg, .png, .gif"
                              class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                              @change="previewFiles"
                            />
                            <b-button
                              variant="outline-primary"
                              class="btn-icon rounded-circle btn-add"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </b-aspect>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
            <!-- Submit -->
            <b-col md="12" class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BFormFile,
  BImgLazy,
  BAspect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardHeader,
    BCardBody,
    BForm,
    BFormTextarea,
    BFormFile,
    BImgLazy,
    BAspect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      model: {},
      activeOptions: Config.activeOptions,
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_supplier/${this.$route.params.id}?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = res.data.data
            this.model.order = 1
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        name: this.model.name,
        position: Number(this.model.position),
        is_active: Number(this.model.is_active),
        phone: this.model.phone,
        address: this.model.address,
        short_content: this.model.short_content,
        email: this.model.email,
        name_bank: this.model.name_bank,
        number_bank: this.model.number_bank,
        branch_bank: this.model.branch_bank,
        tin: this.model.tin,
        business_license: this.model.business_license,
        account_holder_bank: this.model.account_holder_bank,
      }
      if (this.model.avatar !== null) {
        params.avatar = this.model.avatar
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_supplier/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update Supplier success',
                  },
                })
                this.$router.push('/supplier/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
